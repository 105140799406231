import React, { useState } from "react";
import UploadCostModal from "./upload.cost.modal";
import styled from "styled-components";
import SecondaryButton from "../../../../app/components/buttons/secondary";

export default function UploadCost({ studyId, scheduleId, siteGroupId }) {
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  const handleFileUploadClose = () => {
    setFileUploadOpen(false);
    // Remove the page refresh here
  };

  const handleFileUploadContinue = () => {
    setFileUploadOpen(false);
    window.location.reload(); // Refresh the page only when "Continue" is clicked
  };

  return (
    <Container>
      <SecondaryButton onClick={() => setFileUploadOpen(true)}>
        UPLOAD COUNTRY COSTS
      </SecondaryButton>
      {fileUploadOpen && (
        <UploadCostModal
          open={fileUploadOpen}
          onClose={handleFileUploadClose}
          onContinue={handleFileUploadContinue}
          studyId={studyId}
          scheduleId={scheduleId}
          siteGroupId={siteGroupId}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;