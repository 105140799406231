import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import {
  COLUMN_HEADERS,
  DATE_PLACEHOLDER,
  MILESTONE_KEYS,
} from "./table.utils";
import TableCell from "@material-ui/core/TableCell";

const CountryStartUpMilestones = () => {
  const { simulationResults } = useStudyPlan();
  const { startupTable } = simulationResults;

  const renderRow = (item) => (
    <>
      <TableCell
        component="th"
        scope="row"
        style={{ borderColor: "var(--Grey-4)" }}
      >
        <strong>{item.siteGroupName}</strong>
      </TableCell>
      {Object.keys(MILESTONE_KEYS).map((key) => (
        <TableCell
          key={key}
          align="center"
          style={{ whiteSpace: "nowrap", borderColor: "var(--Grey-4)" }}
        >
          {item[key] ?? DATE_PLACEHOLDER}
        </TableCell>
      ))}
    </>
  );

  return (
    <AccordionTable
      width={"100%"}
      summary="Country Start-Up Milestones"
      columns={COLUMN_HEADERS}
      data={startupTable}
      renderRow={renderRow}
    />
  );
};

export default CountryStartUpMilestones;
