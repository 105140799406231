import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
} from "@material-ui/core";
import CustomDatePicker from "../../../../app/components/custom.date.picker";
import { formatDate } from "../../../../app/helpers/utils";
import styled from "styled-components";

const VISIT_INTERVAL_TYPE_OPTIONS = ["Hour", "Day", "Week", "Month", "Year"];

export const NewScheduleForm = ({
  SOEEventParameters,
  handleChange,
  errors,
  classes,
  onToggleChange,
}) => {
  const [isProtocolVersionNA, setIsProtocolVersionNA] = useState(false);
  const [isProtocolDateNA, setIsProtocolDateNA] = useState(false);

  const handleProtocolVersionNAToggle = () => {
    const newNAState = !isProtocolVersionNA;
    setIsProtocolVersionNA(newNAState);
    if (newNAState) {
      handleChange("protocolVersion", "");
    }
    onToggleChange("protocolVersion", newNAState);
  };

  const handleProtocolDateNAToggle = () => {
    const newNAState = !isProtocolDateNA;
    setIsProtocolDateNA(newNAState);
    if (newNAState) {
      handleChange("protocolDate", null);
    }
    onToggleChange("protocolDate", newNAState);
  };

  return (
    <SubContainer>
      <TextField
        style={{ width: "100%" }}
        value={SOEEventParameters.scheduleOfEventsName}
        onChange={(e) => handleChange("scheduleOfEventsName", e.target.value)}
        variant="outlined"
        label="SOE Name"
        InputLabelProps={{ classes }}
        required
        error={!!errors.scheduleOfEventsName}
        inputProps={{
          maxLength: 50,
        }}
        helperText={
          <AlignBetween>
            <span>{errors.scheduleOfEventsName}</span>
            <span>{`${
              (SOEEventParameters.scheduleOfEventsName || "").length
            } / ${50}`}</span>
          </AlignBetween>
        }
      />
      <TextField
        style={{ width: "100%" }}
        value={SOEEventParameters.scheduleOfEventsDescr}
        error={errors.scheduleOfEventsDescr}
        onChange={(e) => handleChange("scheduleOfEventsDescr", e.target.value)}
        variant="outlined"
        label="SOE Description"
        InputLabelProps={{ classes }}
        required
        multiline
        inputProps={{
          maxLength: 2000,
        }}
        rows={4}
        helperText={
          <AlignBetween>
            <span>{errors.scheduleOfEventsDescr}</span>
            <span>{`${
              (SOEEventParameters.scheduleOfEventsDescr || "").length
            } / ${2000}`}</span>
          </AlignBetween>
        }
      />
      <ProtocolVersionContainer>
        <ProtocolInputContainer>
          <FieldWrapper isDisabled={isProtocolVersionNA}>
            <TextField
              style={{ width: "100%" }}
              value={isProtocolVersionNA ? "" : SOEEventParameters.protocolVersion || ""}
              onChange={(e) => !isProtocolVersionNA && handleChange("protocolVersion", e.target.value)}
              variant="outlined"
              label="Protocol Version"
              InputLabelProps={{ classes }}
              required={!isProtocolVersionNA}
              disabled={isProtocolVersionNA}
              error={!isProtocolVersionNA && !!errors.protocolVersion}
              inputProps={{
                maxLength: 25,
              }}
              helperText={
                <AlignBetween>
                  <span>{!isProtocolVersionNA && errors.protocolVersion}</span>
                  <span>{`${
                    (SOEEventParameters.protocolVersion || "").length
                  } / ${25}`}</span>
                </AlignBetween>
              }
            />
          </FieldWrapper>
          <NAToggle>
            <span>Not Available</span>
            <Switch
              checked={isProtocolVersionNA}
              onChange={handleProtocolVersionNAToggle}
              color="primary"
            />
          </NAToggle>
        </ProtocolInputContainer>
      </ProtocolVersionContainer>
      <ProtocolDateContainer>
        <DatePickerWrapper isDisabled={isProtocolDateNA}>
          <CustomDatePicker
            label="Protocol Date"
            onChange={(value) => !isProtocolDateNA && handleChange("protocolDate", formatDate(value))}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            required={!isProtocolDateNA}
            disabled={isProtocolDateNA}
            style={{ zIndex: 100, pointerEvents: isProtocolDateNA ? 'none' : 'auto' }}
            error={!isProtocolDateNA && errors.protocolDate}
            dateFormat={"dd/MMM/yyyy"}
            selected={isProtocolDateNA ? null : SOEEventParameters.protocolDate}
            value={isProtocolDateNA ? null : SOEEventParameters.protocolDate}
          />
        </DatePickerWrapper>
        <NAToggle>
          <span>Not Available</span>
          <Switch
            checked={isProtocolDateNA}
            onChange={handleProtocolDateNAToggle}
            color="primary"
          />
        </NAToggle>
      </ProtocolDateContainer>
      <FormControl
        error={errors.visitIntervalType}
        required
        style={{ width: "100%" }}
        variant="outlined"
      >
        <InputLabel id="visit-interval-type-label-id">
          Visit Interval Type
        </InputLabel>
        <Select
          labelId="visit-interval-type-label-id"
          id="visit-interval-type-id"
          value={SOEEventParameters.visitIntervalType}
          onChange={(event) =>
            handleChange("visitIntervalType", event.target.value)
          }
          label="Visit Interval Type"
        >
          {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        error={errors.visitWindowIntervalType}
        required
        style={{ width: "100%" }}
        variant="outlined"
      >
        <InputLabel id="visit-window-interval-type-label-id">
          Visit Window Interval Type
        </InputLabel>
        <Select
          labelId="visit-window-interval-type-label-id"
          id="visit-window-interval-type-id"
          value={SOEEventParameters.visitWindowIntervalType}
          onChange={(event) =>
            handleChange("visitWindowIntervalType", event.target.value)
          }
          label="Visit Window Interval Type"
        >
          {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </SubContainer>
  );
};

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ProtocolVersionContainer = styled.div`
  width: 100%;
`;

const ProtocolInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1em;
`;

const ProtocolDateContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1em;
`;

const FieldWrapper = styled.div`
  flex: 1;
  opacity: ${props => props.isDisabled ? 0.38 : 1};
`;

const DatePickerWrapper = styled.div`
  flex: 1;
  opacity: ${props => props.isDisabled ? 0.38 : 1};
  pointer-events: ${props => props.isDisabled ? 'none' : 'auto'};
`;

const NAToggle = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;
  
  span {
    order: -1;
  }
`;

export default NewScheduleForm;