import { LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import API from "../../../../app/utils/api";
import FileUploader from "../../../../app/components/file.uploader.excel";
import CustomModal from "../../../../app/components/modal.no.close";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import * as XLSX from "xlsx";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";

const UploadCostModal = ({ onContinue, ...props }) => {
  const { showSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);
  const [uploadErrors, setUploadErrors] = useState([]);

  const extractCountryName = (sheetName) => {
    return sheetName.trim();
  };

  const processSheetData = (worksheet) => {
    const assessments = [];
    const MAX_ROWS = 1000;
    
    for (let row = 1; row <= MAX_ROWS; row++) {
      const costCell = worksheet[`H${row}`];
      const nameCell = worksheet[`G${row}`];
      
      if (!nameCell || !costCell) {
        continue;
      }
  
      const assessmentName = nameCell.v;
      const assessmentCost = parseFloat(costCell.v);
      
      if (assessmentName && !isNaN(assessmentCost)) {
        assessments.push({
          assessmentName,
          assessmentCost
        });
      }
    }
    
    return assessments;
  };

  const handleChange = async (files) => {
    if (files.length === 0) return;
    handleUpload(files);
  };

  const handleUpload = async (files) => {
    if (files.length === 0) return;

    setLoading(true);
    setProgress(0);
    setStatus("Processing country costs...");
    setUploadErrors([]);

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        
        const countryData = [];
        let totalValidSheets = 0;
        
        workbook.SheetNames.forEach(sheetName => {
          const countryName = extractCountryName(sheetName);
          const worksheet = workbook.Sheets[sheetName];
          const assessments = processSheetData(worksheet);
          
          if (assessments.length > 0) {
            totalValidSheets++;
            assessments.forEach(assessment => {
              countryData.push({
                scheduleOfEventsId: props.scheduleId,
                countryName,
                assessmentName: assessment.assessmentName,
                assessmentCost: assessment.assessmentCost
              });
            });
          }
        });

        if (totalValidSheets === 0) {
          setUploadErrors(['No valid data found in any sheet']);
          setStatus("Upload failed - no valid data found.");
          setProgress(100);
          setApiStatus("error");
          return;
        }

        try {
          const response = await API.uploadCost(props.studyId, countryData);
          
          if (response.status === 200) {
            showSnackbar("Country costs uploaded successfully", "success");
            setProgress(100);
            setStatus("Upload completed.");
            setApiStatus(200);
          } else {
            showSnackbar("Failed to upload country costs", "error");
            setProgress(100);
            setStatus("Upload failed.");
            setApiStatus(response.status);
            setUploadErrors([response.data?.message || "Unknown error occurred"]);
          }
        } catch (error) {
          showSnackbar("Failed to upload country costs", "error");
          setProgress(100);
          setStatus("Upload failed.");
          setApiStatus("error");
          setUploadErrors([error.message || "Unknown error occurred"]);
        }
      };
      reader.readAsArrayBuffer(files[0]);
    } catch (error) {
      showSnackbar("Failed to process file", "error");
      setLoading(false);
      setUploadErrors([error.message]);
    }
  };

  const handleContinue = () => {
    props.onClose();
    onContinue();
  };

  return (
    <CustomModal
      size={700}
      {...props}
      onClose={!loading && props.onClose}
      aria-labelledby="Upload Country Costs"
      aria-describedby="upload-cost"
      title="Upload Country Costs"
    >
      <Container>
        {!loading && (
          <VContainer>
            <FileUploader
              acceptedFiles={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": []
              }}
              onFileUpload={handleChange}
            />
          </VContainer>
        )}
        {loading && (
          <VContainer>
            {status !== "Upload completed." && status !== "Upload failed." && (
              <LinearProgress variant="determinate" value={progress} />
            )}
            <StatusText>{status}</StatusText>
            {uploadErrors.length > 0 && (
              <ErrorContainer>
                <ErrorTitle>Errors Found:</ErrorTitle>
                {uploadErrors.map((error, index) => (
                  <ErrorText key={index}>{error}</ErrorText>
                ))}
              </ErrorContainer>
            )}
          </VContainer>
        )}
        <ButtonContainer>
          {loading && apiStatus === 200 ? (
            <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
          ) : (
            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
          )}
        </ButtonContainer>
      </Container>
    </CustomModal>
  );
};

export default UploadCostModal;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  padding-bottom: 0em;
  padding-top: 0;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const StatusText = styled.div`
  font-weight: 500;
  margin-bottom: 1em;
`;

const ErrorContainer = styled.div`
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 1em;
  margin-top: 1em;
`;

const ErrorTitle = styled.div`
  color: #d32f2f;
  font-weight: 500;
  margin-bottom: 0.5em;
`;

const ErrorText = styled.div`
  color: #d32f2f;
  font-size: 0.9em;
  margin-bottom: 0.25em;
`;