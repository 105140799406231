import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "../../../../app/components";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { NewScheduleForm } from "./NewScheduleForm";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";

const NewScheduleEventModal = (props) => {
  const classes = useStyles();
  const { scheduleOfEvents, saveLoading } = useScheduleEvents();
  const [formValid, setFormValid] = useState(false);
  const [SOEEventParameters, setSOEEventParameters] = useState({
    scheduleOfEventsName: "",
    scheduleOfEventsDescr: "",
  });
  const [naToggles, setNaToggles] = useState({
    protocolVersion: false,
    protocolDate: false,
  });
  const [errors, setErrors] = useState({
    scheduleOfEventsName: "",
    scheduleOfEventsDescr: "",
    visitIntervalType: "",
    visitWindowIntervalType: "",
    protocolVersion: "",
    protocolDate: "",
  });

  useEffect(() => {
    const isValid = 
      SOEEventParameters["scheduleOfEventsDescr"] &&
      SOEEventParameters["scheduleOfEventsName"] &&
      SOEEventParameters["visitIntervalType"] &&
      SOEEventParameters["visitWindowIntervalType"] &&
      (naToggles.protocolVersion || SOEEventParameters["protocolVersion"]) &&
      (naToggles.protocolDate || SOEEventParameters["protocolDate"]) &&
      !errors["scheduleOfEventsDescr"] &&
      !errors["scheduleOfEventsName"] &&
      !errors["visitIntervalType"] &&
      !errors["visitWindowIntervalType"] &&
      (!errors["protocolVersion"] || naToggles.protocolVersion) &&
      (!errors["protocolDate"] || naToggles.protocolDate);
    
    setFormValid(isValid);
  }, [SOEEventParameters, errors, naToggles]);

  const handleChange = (key, value) => {
    setSOEEventParameters({ ...SOEEventParameters, [key]: value });
    if (!value && !naToggles[key]) {
      setErrors({ ...errors, [key]: "This field is required" });
    } else {
      setErrors({ ...errors, [key]: "" });
    }

    if (key === "scheduleOfEventsName") {
      scheduleOfEvents.forEach((sp) => {
        if (
          sp?.scheduleOfEventsName.toLowerCase().trim() ===
          value.toLowerCase().trim()
        ) {
          setErrors({
            ...errors,
            [key]: "Schedule of Events name must be unique",
          });
        }
        if (!value.replace(/\s/g, "").length) {
          setErrors({
            ...errors,
            [key]: "SOE name must contain at least 1 character",
          });
        }
      });
    }

    if (key === "scheduleOfEventsDescr") {
      if (!value.replace(/\s/g, "").length) {
        setErrors({
          ...errors,
          [key]: "SOE description must contain at least 1 character",
        });
      }
    }
  };

  const handleToggleChange = (field, isNA) => {
    setNaToggles(prev => ({ ...prev, [field]: isNA }));
    if (isNA) {
      setErrors(prev => ({ ...prev, [field]: "" }));
    }
  };

  return (
    <Modal
      size={700}
      {...props}
      aria-labelledby="New Schedule of Events"
      aria-describedby="Create a new schedule of events"
      title="New Schedule of Events"
    >
      <Container>
        <NewScheduleForm
          handleChange={handleChange}
          errors={errors}
          SOEEventParameters={SOEEventParameters}
          classes={classes}
          onToggleChange={handleToggleChange}
        />
        <ButtonContainer>
          <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={saveLoading || !formValid}
            onClick={() => props.onContinue(SOEEventParameters)}
          >
            {saveLoading && <CircularProgress size="1em" />}
            {!saveLoading && "Continue"}
          </PrimaryButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default NewScheduleEventModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});