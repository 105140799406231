import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {
  checkIfEmpty,
  checkIfInteger,
  checkIfInRange,
  checkIfDecimal,
} from "../../helpers/validation";

const SpecialTextField = ({
  value,
  onChange,
  label,
  inputProps = {},
  onBlur,
  endadornment = null,
  altlabel = null,
  showArrows = true,
  showCustomError = null,
  customMessageError = null,
  customValidation = null,
  setFieldErrors = null,
  showTooltip = true,
  setHasErrors = null,
  ...props
}) => {
  const inputClasses = useCustomInputStyles({ showArrows });
  const [validationErrorMessage, setValidationErrorMessage] = useState();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: altlabel ? "120px" : "80px",
    },
  }));
  const classes = useStyles();

  const handleChange = (e) => {
    if (e.target.value === "") {
      onChange(null);
    } else {
      onChange(e.target.value);
    }
  };

  const handleBlur = (e) => {
    onBlur(e.target.value);
  };

  const validateField = (e) => {
    let containsErrors = false;
    if (customValidation !== null) {
      const { range, validateEmpty, checkIfInt, checkIfDec } = customValidation;

      let re = /^$|^[0-9\b]+$/;

      if (checkIfDec || (!checkIfDec && !checkIfInt)) {
        re = /^$|^[0-9\b]+(\.[0-9]*)?$/;
      }

      if (!re.test(e.target.value)) {
        return;
      }

      const duration = parseInt(e.target.value, 10);
      if (range) {
        let errors = [checkIfInRange(duration, range, checkIfDec)];

        if (validateEmpty) {
          errors.unshift(checkIfEmpty(e.target.value));
        }

        if (checkIfInt) {
          errors.push(checkIfInteger(e.target.value));
        }

        if (checkIfDec) {
          errors.push(checkIfDecimal(e.target.value));
        }

        errors = errors.filter((error) => error !== "");
        if (errors.length > 0) {
          containsErrors = true;
          if (typeof setHasErrors === "function") {
            setHasErrors(errors);
          }

          if (typeof setFieldErrors === "function") {
            setFieldErrors((prevState) => ({
              ...prevState,
              [e.target?.name]: {
                ...prevState[e.target?.name],
                errors: errors,
              },
            }));
          }
        } else {
          if (typeof setHasErrors === "function") {
            setHasErrors([]);
          }
        }
        setValidationErrorMessage(errors.join("  "));
      }
    }

    if (!containsErrors && typeof setFieldErrors === "function") {
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        delete newState[e.target?.name];
        return newState;
      });
    }

    handleChange(e);
  };

  function handleFirstChange(event) {
    let value = event.target.value;
    if (value && value.toString().startsWith(".")) {
      value = "0" + value;
    }

    const modifiedEvent = {
      ...event,
      target: {
        ...event.target,
        value: value,
      },
    };

    if (onChange) {
      onChange(modifiedEvent);
    }
    if (validateField) {
      validateField(modifiedEvent);
    }
  }

  return (
    <Container hasMargin={label !== undefined}>
      <Tooltip
        title={
          showCustomError
            ? customMessageError
            : showTooltip
            ? validationErrorMessage
            : ""
        }
        open={showCustomError || validationErrorMessage ? true : false}
        placement="top"
        classes={{ tooltip: inputClasses.customTooltip }}
        PopperProps={{
          className: showCustomError ? "customTooltip" : undefined,
          popperOptions: {
            modifiers: [
              {
                name: "computeStyles",
                enabled: true,
                phase: "write",
                fn: (data) => {
                  if (data && data.offsets && data.offsets.popper) {
                    data.offsets.popper.top = data.offsets.reference.top - 45;
                    data.offsets.popper.left = data.offsets.reference.left;
                  }
                  return data;
                },
              },
            ],
          },
        }}
      >
        <TextField
          name={label?.trim().replace(/\s+/g, "").toLowerCase()}
          style={{
            background: props.disabled ? "#eeeeee" : "#ffffff",
            border: "1px solid var(--Grey-1)",
          }}
          type="number"
          value={value ?? ""}
          variant="outlined"
          classes={classes}
          InputProps={{
            classes: inputClasses,
          }}
          inputProps={{
            min: 1,
            type: "number",
            ...inputProps,
          }}
          disabled={props.disabled}
          {...props}
          onChange={handleFirstChange}
          onBlur={onBlur && handleBlur}
          label={altlabel}
        />
      </Tooltip>

      {label && (
        <Typography
          style={{
            marginLeft: "1em",
            display: "flex",
            alignItems: "center",
          }}
        >
          {label}
          {endadornment}
          {props.required && (
            <span
              style={{
                color: "red",
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
    </Container>
  );
};

export default SpecialTextField;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  margin-right: ${(p) => (p.hasMargin ? "1em" : 0)};
`;

const useCustomInputStyles = makeStyles({
  input: {
    textAlign: "center",
  },
  root: (props) => ({
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
      {
        "-webkit-appearance": props.showArrows ? "" : "none",
        margin: 0,
      },
    '& input[type="number"]': {
      "-moz-appearance": props.showArrows ? "" : "textfield",
    },
  }),
  customTooltip: {
    backgroundColor: "white",
    color: "red",
    border: "1px solid #dadde9",
    fontSize: "14px",
  },
});
