import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DragIndicator, RemoveCircle } from "@material-ui/icons";
import {
  checkNumberLength,
  isValidNumberStringValues,
} from "../../utils/helpers";
import { Draggable } from "react-beautiful-dnd";
import { FIELD_TYPES } from "../constants";
import React, { useState, useCallback, useRef } from "react";
import VisitInputField from "./visit.input.field";
import styled from "styled-components";
import {
  VISIT_CATEGORY_TYPES,
  VISIT_VARIANCE_TYPE_OPTIONS,
} from "../constants";

export default function TableRow({
  sv,
  removeRow,
  onVisitsChange,
  value,
  index,
  fieldType,
}) {
  const timeoutIds = useRef({});

  const commentTypeKey = React.useMemo(() => {
    if (fieldType === FIELD_TYPES.VISIT) return "commentsVisit";
    if (fieldType === FIELD_TYPES.COST) return "commentsCost";
    if (fieldType === FIELD_TYPES.MEDICAL) return "commentsClinicalStrategy";
    if (fieldType === FIELD_TYPES.DATA_MANAGEMENT) return "commentsDataManagement";
    return "";
  }, [fieldType]);

  const [commentTypeKeyState, setCommentTypeKeyState] = useState(sv[commentTypeKey] || "");
  const [visitVarianceState, setVisitVarianceState] = useState(sv.visitVariance || "");
  const [visitStudyDayState, setVisitStudyDayState] = useState(sv.visitStudyDay || 0);

  const debounce = useCallback((func, wait, fieldKey) => {
    return function (...args) {
      const timeoutKey = `${sv.scheduleOfEventsVisitId || sv.key}_${fieldKey}`;
      
      if (timeoutIds.current[timeoutKey]) {
        clearTimeout(timeoutIds.current[timeoutKey]);
      }

      timeoutIds.current[timeoutKey] = setTimeout(() => {
        timeoutIds.current[timeoutKey] = null;
        func.apply(null, args);
      }, wait);
    };
  }, [sv.scheduleOfEventsVisitId, sv.key]);

  // Create separate debounced handlers for different fields
  const debouncedHandlers = {
    visitStudyDay: useCallback(
      debounce(
        (value) => onVisitsChange(
          "visitStudyDay", 
          value, 
          sv.scheduleOfEventsVisitId || sv.key
        ),
        500,
        'visitStudyDay'
      ),
      [debounce, sv.scheduleOfEventsVisitId, sv.key]
    ),
    visitVariance: useCallback(
      debounce(
        (value) => onVisitsChange(
          "visitVariance", 
          value, 
          sv.scheduleOfEventsVisitId || sv.key
        ),
        500,
        'visitVariance'
      ),
      [debounce, sv.scheduleOfEventsVisitId, sv.key]
    ),
    comments: useCallback(
      debounce(
        (value) => onVisitsChange(
          commentTypeKey, 
          value, 
          sv.scheduleOfEventsVisitId || sv.key
        ),
        500,
        'comments'
      ),
      [debounce, commentTypeKey, sv.scheduleOfEventsVisitId, sv.key]
    )
  };

  // Cleanup timeouts on unmount
  React.useEffect(() => {
    return () => {
      Object.values(timeoutIds.current).forEach(timeoutId => {
        if (timeoutId) clearTimeout(timeoutId);
      });
    };
  }, []);

  const renderRow = (dndProps) => {
    const { dragHandleProps, ...remainingDnDProps } = dndProps;
    return (
      <Row {...remainingDnDProps}>
        <Cell width={value === 0 ? "12%" : "25%"}>
          {value === 0 ? (
            <>
              <DragHandleContainer {...dragHandleProps}>
                <DragIndicator />
              </DragHandleContainer>
              <SelectContainer>
                <FormControl style={{ width: "100%" }} size="small" variant="outlined">
                  <TextField
                    variant="outlined"
                    fullWidth
                    select
                    id="visit-category"
                    value={sv.visitCategory}
                    onChange={(e) => {
                      onVisitsChange(
                        "visitCategory",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key,
                        "string"
                      );
                    }}
                    size="small"
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={sv.visitCategory}
                  >
                    {VISIT_CATEGORY_TYPES.map((vc) => (
                      <MenuItem value={vc} key={vc}>
                        {vc}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </SelectContainer>
            </>
          ) : (
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                padding: "5px",
              }}
              title={sv.visitCategory}
            >
              {sv.visitCategory}
            </Typography>
          )}
        </Cell>
        <Cell width={value === 0 ? "18%" : "25%"}>
          {value === 0 ? (
            <VisitInputField onVisitsChange={onVisitsChange} sv={sv} />
          ) : (
            <Typography variant="body2">{sv.visitName}</Typography>
          )}
        </Cell>
        <Cell width={value === 0 ? "6%" : "10%"}>
          {value === 0 ? (
            <TextField
              style={{ width: "100%" }}
              value={visitStudyDayState}
              onChange={(e) => {
                if (!isValidNumberStringValues(e.target.value) ||
                    !checkNumberLength(e.target.value, 4, 0, 9999)) return;
                
                setVisitStudyDayState(e.target.value);
                debouncedHandlers.visitStudyDay(e.target.value);
              }}
              variant="outlined"
              size="small"
              disabled={sv.visitCategory === "Unscheduled"}
            />
          ) : (
            <Typography variant="body2">{sv.visitStudyDay}</Typography>
          )}
        </Cell>

        <Cell width={value === 0 ? "6%" : "10%"}>
          {value === 0 ? (
            <FormControl style={{ width: "100%" }} size="small" variant="outlined">
              <Select
                id="visit-variance-type"
                value={sv.visitVarianceType}
                onChange={(e) => {
                  onVisitsChange(
                    "visitVarianceType",
                    e.target.value,
                    sv.scheduleOfEventsVisitId || sv.key,
                    "string"
                  );
                }}
              >
                {VISIT_VARIANCE_TYPE_OPTIONS.map((vvt) => (
                  <MenuItem value={vvt.value} key={vvt.value}>
                    {vvt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="body2">{sv.visitVarianceType}</Typography>
          )}
        </Cell>
        <Cell width={value === 0 ? "6%" : "10%"}>
          {value === 0 ? (
            <TextField
              style={{ width: "100%" }}
              value={visitVarianceState}
              onChange={(e) => {
                if (!isValidNumberStringValues(e.target.value) ||
                    !checkNumberLength(e.target.value, 5, 0, 9999)) return;

                setVisitVarianceState(e.target.value);
                debouncedHandlers.visitVariance(e.target.value);
              }}
              variant="outlined"
              size="small"
            />
          ) : (
            <Typography variant="body2">{sv.visitVariance}</Typography>
          )}
        </Cell>

        {(fieldType === FIELD_TYPES.COST || value !== 0) && (
          <>
            <Cell width={value === 0 ? "6%" : "10%"}>
              <TextField
                style={{ width: "100%" }}
                value={sv.visitOverheadPct ?? ""}
                onChange={(e) => {
                  if (!isValidNumberStringValues(e.target.value) ||
                      !checkNumberLength(e.target.value, 3, 0, 100)) return;

                  onVisitsChange(
                    "visitOverheadPct",
                    e.target.value,
                    sv.scheduleOfEventsVisitId || sv.key
                  );
                }}
                variant="outlined"
                size="small"
              />
            </Cell>
            <Cell width={value === 0 ? "6%" : "10%"}>
              <Typography variant="body2">{sv.visitCost}</Typography>
            </Cell>
          </>
        )}
        {value === 0 && (
          <>
            {fieldType === FIELD_TYPES.MEDICAL && (
              <>
                <Cell width="6%">
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.miEstSDRSDV ?? ""}
                    onChange={(e) => {
                      if (!isValidNumberStringValues(e.target.value) ||
                          !checkNumberLength(e.target.value, 6, 2)) return;
                      onVisitsChange(
                        "miEstSDRSDV",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width="6%">
                  <Checkbox
                    checked={sv.miSampleVisit}
                    onChange={(e) => {
                      onVisitsChange(
                        "miSampleVisit",
                        e.target.checked,
                        sv.scheduleOfEventsVisitId || sv.key,
                        "checkbox"
                      );
                    }}
                    name="detailsMonthly"
                  />
                </Cell>
                <Cell width="6%">
                  <Typography variant="body2">
                    {sv.miSuggestedOnSiteSDRSDV}
                  </Typography>
                </Cell>
                <Cell width="6%">
                  <Typography variant="body2">
                    {sv.miSuggestedCDAReview}
                  </Typography>
                </Cell>
              </>
            )}

            {fieldType === FIELD_TYPES.DATA_MANAGEMENT && (
              <>
                <Cell width="6%">
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmUniqueCRFs ?? ""}
                    onChange={(e) => {
                      if (!isValidNumberStringValues(e.target.value) ||
                          !checkNumberLength(e.target.value, 6, 2)) return;
                      onVisitsChange(
                        "dmUniqueCRFs",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width="6%">
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmNonUniqueCRFs ?? ""}
                    onChange={(e) => {
                      if (!isValidNumberStringValues(e.target.value) ||
                          !checkNumberLength(e.target.value, 6, 2)) return;
                      onVisitsChange(
                        "dmNonUniqueCRFs",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
              </>
            )}
          </>
        )}
        {value === 0 && (
          <Cell
            width={() => {
              switch (commentTypeKey) {
                case "commentsVisit":
                  return "50%";
                case "commentsCost":
                  return "38%";
                case "commentsClinicalStrategy":
                  return "26%";
                case "commentsDataManagement":
                  return "38%";
                default:
                  return "0%";
              }
            }}
          >
            <TextField
              key={commentTypeKey}
              value={commentTypeKeyState}
              onChange={(e) => {
                setCommentTypeKeyState(e.target.value);
                debouncedHandlers.comments(e.target.value);
              }}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 250,
              }}
              style={{ width: "100%" }}
            />
          </Cell>
        )}
        {value === 0 ? (
          <Cell width="2%">
            <RemoveButtonContainer
              onClick={() => removeRow(sv.scheduleOfEventsVisitId || sv.key)}
            >
              <RemoveCircle style={{ fontSize: "1rem" }} />
            </RemoveButtonContainer>
          </Cell>
        ) : null}
      </Row>
    );
  };

  return (
    <>
      {value === 0 ? (
        <Draggable
          draggableId={(sv.key || sv.scheduleOfEventsVisitId).toString()}
          index={index}
        >
          {(provided) =>
            renderRow({
              dragHandleProps: provided.dragHandleProps,
              ...provided.draggableProps,
              ref: provided.innerRef,
            })
          }
        </Draggable>
      ) : (
        renderRow({})
      )}
    </>
  );
}

const DragHandleContainer = styled.div`
  color: #555;
  width: 2.5rem;
`;

const RemoveButtonContainer = styled.div`
  color: red;
  cursor: pointer;
  background: white;
  padding: 1px;
  border-radius: 50%;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid var(--Grey-4)")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--Grey-1)" : "var(--White)"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid var(--Grey-4);
  padding-inline: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid var(--Grey-4)" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--Grey-4)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--Grey-4)" : "")};
`;
const SelectContainer = styled.div`
  flex: 1;
  min-width: 0;
  padding: 5px;
  overflow: hidden;
`;
