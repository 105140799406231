import { InputAdornment, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";  
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";

export default function CustomDatePicker({
  error,
  value,
  onChange,
  style,
  label,
  required,
  small,
  placeholderText,
  ...props
}) {
  let [tempValue, setTempValue] = useState(null);

  useEffect(() => {
    const utcDate = value ? new Date(value) : null;
    const localDate = utcDate ? new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000) : null;
    setTempValue(localDate);
  }, [value]);

  const CustomDateInput = ({ value, onChange, onClick }) => (
    <TextField
      placeholder={placeholderText || "DD/MMM/YYYY"}
      style={{ width: "100%" }}
      value={value}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment
            style={{ cursor: "pointer" }}
            position="start"
          >
            {" "}
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
      error={error}
      onClick={onClick}
      label={label}
      required={required}
      size={small ? "small" : "medium"}
    />
  );

  return (
    <div
      style={{
        ...style,
        overflow: "inherit",
      }}
    >
      <DatePicker
        {...props}
        selected={tempValue}
        onChange={(value) => {
          setTempValue(value);
          const utcDate = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
          onChange(utcDate);
        }}
        customInput={<CustomDateInput />}
      />
    </div>
  );
}