import React, { useContext, useState } from "react";
import { Snackbar } from "../components";

const SnackbarContext = React.createContext();

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {
  const [snackbars, setSnackbars] = useState([]);
  
  // Generate unique ID for each snackbar
  const generateId = () => `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  
  const showSnackbar = (message, severity = "success") => {
    const newSnackbar = {
      id: generateId(),
      message,
      severity,
      open: true
    };
    
    setSnackbars(current => [...current, newSnackbar]);
  };
  
  const handleCloseSnackbar = (id) => {
    setSnackbars(current => 
      current.map(snackbar => 
        snackbar.id === id 
          ? { ...snackbar, open: false }
          : snackbar
      )
    );
    
    // Remove the snackbar after animation completes
    setTimeout(() => {
      setSnackbars(current => 
        current.filter(snackbar => snackbar.id !== id)
      );
    }, 300); // Adjust timing based on your animation duration
  };
  
  const value = {
    showSnackbar,
  };
  
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <div className="fixed bottom-0 right-0 p-4 space-y-2">
        {snackbars.map((snackbar, index) => (
          <Snackbar
            key={snackbar.id}
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
            onClose={() => handleCloseSnackbar(snackbar.id)}
            style={{
              marginBottom: `${index * 16}px`, // Stack snackbars with spacing
              transition: 'all 0.3s ease'
            }}
          />
        ))}
      </div>
    </SnackbarContext.Provider>
  );
}